import React from "react"
import { Container } from "react-bootstrap"

const RoomList = ({ siteTitle }) => (
  <section className="component roomList">
    <Container fluid>
      <div className="skd-widget" 
        data-skd-widget="offer-list" 
        data-skd-send-to-groups="B" 
        data-skd-listen-to-groups="A"
      >
        {/* <div style="width:100%; min-height:100px; text-align:center;">
          <img src="https://static.seekda.com/assets/images/skd_spinner.gif" />
          <noscript>Your browser doesn't support JavaScript or it has been disabled. To use the booking engine, please make sure to get JavaScript running.</noscript>
        </div> */}
      </div>
    </Container>
  </section>
)

export default RoomList
